import {
    isRouteErrorResponse,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useLocation,
    useMatches,
    useRouteError, useSubmit,
} from "@remix-run/react";
import {ActionFunctionArgs, json, LoaderFunctionArgs, redirect} from "@remix-run/node";

import "./tailwind.css";
import * as Sentry from "@sentry/remix";
import {useEffect, useRef, useState} from "react";
import {captureRemixErrorBoundaryError} from "@sentry/remix";
import {consentCookie} from "~/cookies";
import {Dialog, DialogPanel, DialogTitle, Transition} from "@headlessui/react";
import StyledLink from "~/components/StyledLink";
import ToggleButton from "~/components/ToggleButton";
import Footer, {IMPRINT, PRIVACY} from "~/components/Footer";


const XMarkIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);


export async function loader({request}: LoaderFunctionArgs) {
    // 1.1) Cookie-Header parsen
    const cookieHeader = request.headers.get("Cookie");
    let metaPixelAllowed: boolean | null = null;
    let vimeoAllowed: boolean | null = null;
    let spotifyAllowed: boolean | null = null;
    let displayCookieConsent = true;

    if (cookieHeader) {
        const cookieData = await consentCookie.parse(cookieHeader);
        metaPixelAllowed = cookieData?.metaPixelAllowed ?? null;
        vimeoAllowed = cookieData?.vimeoAllowed ?? null;
        spotifyAllowed = cookieData?.spotifyAllowed ?? null;
        if (metaPixelAllowed !== null || vimeoAllowed !== null) {
            displayCookieConsent = false;
        }
    }

    // 1.2) Sonstige ENV-Sachen (Sentry DSN)
    // Das hattest du bereits im Code
    return json({
        ENV: {
            SENTRY_DSN: process.env.SENTRY_DSN || "",
        },
        // Cookie-Infos
        displayCookieConsent,
        metaPixelAllowed,
        vimeoAllowed,
        spotifyAllowed
    });
}

/** ---------------------------
 *  2) Action in Root
 * -------------------------- */
export async function action({request}: ActionFunctionArgs) {
    const formData = await request.formData();

    const metaPixelConsent = formData.get("metaPixelConsent") === "true";
    const vimeoConsent = formData.get("vimeoConsent") === "true";
    const spotifyConsent = formData.get("spotifyConsent") === "true";

    const cookieValue = await consentCookie.serialize({
        metaPixelAllowed: metaPixelConsent,
        vimeoAllowed: vimeoConsent,
        spotifyAllowed: spotifyConsent
    });

    const referer = request.headers.get("Referer") || "/";

    // Wir könnten einen Redirect zurück auf dieselbe Seite machen:
    return redirect(referer, {
        headers: {"Set-Cookie": cookieValue},
    });
}


export function Layout({children}: { children: React.ReactNode }) {
    return (
        <html lang="de">
        <head>
            <title>cemalettinoeztuerk.de</title>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <script
                src={"https://cdn.usefathom.com/script.js"}
                data-spa="auto"
                data-site="DOUOCHDQ"
                defer
            ></script>
            <Meta/>
            <Links/>
        </head>
        <body>
        {children}
        <ScrollRestoration/>
        <Scripts/>
        </body>
        </html>
    );
}

export const ErrorBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        // Für Fehler mit einem HTTP-Status
        if (error.status === 404) {
            return <>
                <main className="grid h-full min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <p className="text-base font-semibold text-blue-500">404</p>
                        <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                            Seite nicht gefunden
                        </h1>
                        <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                            Leider konnten wir die von dir gesuchte Seite nicht finden.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="/"
                                className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Hier geht es zur Startseite.
                            </a>
                        </div>
                    </div>
                </main>
            </>

        }
        if (error.status === 500) {
            return <div>Interner Serverfehler (500).</div>;
        }
        return (
            <div>
                Ein Fehler ist aufgetreten: {error.status} {error.statusText}
            </div>
        );
    }

    // Für andere Arten von Fehlern
    console.error(error);
    return <div>Ein unerwarteter Fehler ist aufgetreten.</div>;
};

export default function App() {
    const data = useLoaderData<typeof loader>();
    const submit = useSubmit();

    // Lokale States (UI)
    const [show, setShow] = useState(data.displayCookieConsent);
    const [userPrefMetaPixel, setUserPrefMetaPixel] = useState(
        data.metaPixelAllowed ?? false
    );
    const [userPrefVimeo, setUserPrefVimeo] = useState(data.vimeoAllowed ?? false);
    const [userPrefSpotify, setUserPrefSpotify] = useState(data.spotifyAllowed ?? false);

    // Refs für direkten Zugriff
    const metaPixelRef = useRef(data.metaPixelAllowed);
    const vimeoRef = useRef(data.vimeoAllowed);
    const spotifyRef = useRef(data.spotifyAllowed);

    // Dialog
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [changeCookieSettingButtonVisibility] = useState(!data.displayCookieConsent);


    useEffect(() => {
        if (window.ENV && window.ENV.SENTRY_DSN) {
            Sentry.init({
                dsn: window.ENV.SENTRY_DSN,
                tracesSampleRate: 1,

                integrations: [
                    Sentry.browserTracingIntegration({
                        useEffect,
                        useLocation,
                        useMatches,
                    }),
                ],

                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1,
            });
        } else {
            console.warn("SENTRY_DSN ist nicht definiert");
        }
    }, []);

    function clientSideReload() {
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    // Erlaubt alle Cookies
    function allowAllCookies() {
        metaPixelRef.current = true;
        vimeoRef.current = true;

        setUserPrefMetaPixel(true);
        setUserPrefVimeo(true);

        const formData = new FormData();
        formData.append("metaPixelConsent", "true");
        formData.append("vimeoConsent", "true");
        formData.append("spotifyConsent", "true");
        // Submit an root-Route ("/")
        submit(formData, {method: "post", action: "/"});

        clientSideReload();
    }

    // Lehnt alle Cookies ab
    function rejectAllCookies() {
        metaPixelRef.current = false;
        vimeoRef.current = false;

        setUserPrefMetaPixel(false);
        setUserPrefVimeo(false);

        const formData = new FormData();
        formData.append("metaPixelConsent", "false");
        formData.append("vimeoConsent", "false");
        submit(formData, {method: "post", action: "/"});

        clientSideReload();
    }

    // Toggle MetaPixel
    function handleSpotifyConsent(consent: boolean) {
        spotifyRef.current = consent;
        setUserPrefSpotify(consent);

        const formData = new FormData();
        formData.append("metaPixelConsent", String(metaPixelRef.current));
        formData.append("vimeoConsent", String(vimeoRef.current));
        formData.append("spotifyConsent", String(consent));
        submit(formData, {method: "post", action: "/"});

        if (!consent) {
            cleanupSpotify();
            clientSideReload();
        }
    }

    function handleMetaPixelConsent(consent: boolean) {
        metaPixelRef.current = consent;
        setUserPrefMetaPixel(consent);

        const formData = new FormData();
        formData.append("metaPixelConsent", String(consent));
        formData.append("vimeoConsent", String(vimeoRef.current));
        submit(formData, {method: "post", action: "/"});

        if (!consent) {
            cleanupMetaPixel();
        }
        clientSideReload()
    }

    function cleanupSpotify() {

        // Lösche alle relevanten Cookies
        const cookiesToDelete = [
            "_fbp",
            "_ga",
            "_ga_BMC5VGR8YS",
            "_ga_S0T2DJJFZM",
            "_ga_S35RN5WNT2",
            "_ga_ZWG1NSHWD8",
            "_ga_ZWRF3NLZJZ",
            "_gcl_au",
            "_gid",
            "_tt_enable_cookie",
            "_ttp",
            "sp_adid",
            "sp_dc",
            "sp_gaid",
            "sp_key",
            "sp_landing",
            "sp_last_utm",
            "sp_t",
            "sss"
        ];

        cookiesToDelete.forEach((cookie) => deleteCookie(cookie));
    }

    function cleanupMetaPixel() {
        // Entferne das Pixel Skript aus dem DOM
        const pixelScript = document.querySelector(
            'script[src="https://connect.facebook.net/en_US/fbevents.js"]'
        );
        if (pixelScript && pixelScript.parentNode) {
            pixelScript.parentNode.removeChild(pixelScript);
        }

        // Lösche globale `fbq` und `_fbq` Objekte
        if (window.fbq) {
            window.fbq = undefined;
            window._fbq = undefined;
        }

        // Lösche alle relevanten Cookies
        ["_fbp", "datr"].forEach((cookie) => deleteCookie(cookie));

        // Entferne lokale und Session Storage Einträge
        localStorage.removeItem("_fbp");
        sessionStorage.removeItem("_fbp");
    }

    function deleteCookie(name: string) {
        const domains = [window.location.hostname, `.${window.location.hostname}`];

        const paths = ["/", "/app", "/subpath"]; // Ergänze hier relevante Pfade
        domains.forEach((domain) => {
            paths.forEach((path) => {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain}`;
            });
        });
    }

    const handleTikTokPixel = (consent: boolean) => {
        console.log("handleTikTokPixel:", consent)
    }

    const handleManyChatPixel = (consent: boolean) => {
        console.log("handleManyChatPixel:", consent)
    }

    const handleGoogleAdsConversion = (consent: boolean) => {
        console.log("handleGoogleAdsConversion:", consent)
    }
    const handleGoogleAdsRemarketing = (consent: boolean) => {
        console.log("handleGoogleAdsRemarketing:", consent)
    }

    const handleAdSenseConsent = (consent: boolean) => {
        console.log("handleAdSenseConsent:", consent)
    }

    const handleVimeoConsent = (consent: boolean) => {
        vimeoRef.current = consent;
        setUserPrefVimeo(consent);

        const formData = new FormData();
        formData.append("metaPixelConsent", String(metaPixelRef.current));
        formData.append("vimeoConsent", consent.toString());
        submit(formData, {method: "post", action: "/"});


        if (!consent) {
            deleteVimeoCookies();
            clientSideReload();
        }
    }

    function deleteVimeoCookies() {
        const vimeoCookies = ["vuid", "player", "_cfuvid", "__cf_bm"]; // Bekannte Vimeo-Cookies
        const domains = [
            window.location.hostname, // Aktuelle Domain
            `.${window.location.hostname}`, // Subdomain mit führendem Punkt
            "vimeo.com", // Externe Domain
            ".vimeo.com", // Externe Subdomain
        ];
        const paths = ["/", "/videos", "/app"]; // Typische Pfade

        vimeoCookies.forEach((cookie) => {
            domains.forEach((domain) => {
                paths.forEach((path) => {
                    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain}`;
                });
            });
        });
    }

    return (
        <>
            {show && (
                <div
                    key={show.toString()}
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-center justify-center px-4 py-6 xl:items-end xl:justify-end"
                >
                    <div className="flex w-full max-w-md flex-col items-center space-y-4">
                        <Transition
                            show={show}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
                            enterTo="opacity-100 translate-y-0 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
                            leaveTo="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
                            afterLeave={() => setShow(false)}
                        >
                            <div
                                className="pointer-events-auto w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="w-0 flex-1">
                                            <p className="mt-1 text-sm font-normal text-gray-500">
                                                Wir verwenden Cookies, um grundlegende Funktionen sowie
                                                deine Präferenzen zu sichern und unsere Website
                                                fortlaufend für dich zu optimieren. Wenige unserer
                                                Dienstleister haben Server in den USA, wodurch
                                                personenbezogene Daten, wie deine IP-Adresse, dorthin
                                                übertragen werden können. Mit deiner Zustimmung stimmst
                                                du dieser Übermittlung zu. Details dazu findest du in
                                                unserer{" "}
                                                <StyledLink to={"/datenschutz"} openInNewTab={true}>
                                                    Datenschutzerklärung
                                                </StyledLink>
                                                .
                                            </p>
                                            <div className="mt-4 flex">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset hover:text-blue-400"
                                                    onClick={() => {
                                                        allowAllCookies()
                                                    }}
                                                >
                                                    Alle Cookies zulassen
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset hover:text-blue-400"
                                                    onClick={() => {
                                                        rejectAllCookies()
                                                    }}
                                                >
                                                    Alle Cookies ablehnen
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            )}
            {changeCookieSettingButtonVisibility && (
                <div className="fixed bottom-4 right-4 bg-gray-200 p-3 rounded-lg shadow-lg">
                    <button
                        type="button"
                        className="text-sm font-semibold text-blue-500 hover:text-blue-400"
                        onClick={() => setIsDialogOpen(true)}
                    >
                        Cookie-Einstellungen ändern
                    </button>
                </div>
            )}

            {isDialogOpen && (
                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} className="relative z-10">
                    <div className="fixed inset-0"/>
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <DialogPanel
                                    className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out sm:duration-700">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <DialogTitle className="text-base font-semibold text-gray-900">
                                                    Cookie-Einstellungen
                                                </DialogTitle>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => setIsDialogOpen(false)}
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        <span className="sr-only">Schließen</span>
                                                        <XMarkIcon aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <p className="text-sm text-gray-600">
                                                Hier kannst du deine Cookie-Einstellungen anpassen. Sobald du ein Cookie
                                                aktivierst oder deaktivierst,
                                                wird die Seite neu geladen, um deine Änderungen auf der gesamten Domain
                                                cemalettinoeztuerk.de zu übernehmen.
                                            </p>

                                            <h2 className="text-2xl mt-6">Erforderlich</h2>
                                            <p className="text-gray-700 my-4">
                                                Um dir ein nahtloses Nutzererlebnis ermöglichen zu können, verwenden
                                                wir
                                                technisch notwendige Cookies.
                                            </p>

                                            <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                            <p className="text-gray-700 mb-6">
                                                Beim ersten Aufruf unserer Website und Login auf unserem Kundenplattform
                                                wird jeweils
                                                ein Cookie abgesetzt, um dich nicht bei jedem Aufruf nach deiner
                                                Cookie-Präferenzen
                                                oder Login-Daten zu fragen.
                                            </p>

                                            <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                Informationen zu
                                                Cookies</h2>
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full bg-white border border-gray-300">
                                                    <thead className="bg-gray-100">
                                                    <tr>
                                                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="text-sm text-gray-700">
                                                    <tr>
                                                        <td className="border px-4 py-2">_auth</td>
                                                        <td className="border px-4 py-2">
                                                            Dieses Cookie authentifiziert Nutzer nach dem Login,
                                                            ermöglicht den Zugriff auf geschützte Bereiche und sichert
                                                            eine nahtlose Nutzung der Funktionen, die eine Anmeldung
                                                            erfordern.
                                                        </td>
                                                        <td className="border px-4 py-2">Sitzung</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border px-4 py-2">userConsent</td>
                                                        <td className="border px-4 py-2">
                                                            Speichert Nutzerpräferenzen (wie z.B. für Video- u.
                                                            Audio-Player)
                                                        </td>
                                                        <td className="border px-4 py-2">1 Jahr</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                Erforderliche Einwilligung
                                            </h4>
                                            <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={true}
                                                                    onToggle={() => {
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            </span>
                                                <label htmlFor="spotify" className="text-gray-700">
                                                    aktivieren
                                                </label>
                                            </div>

                                            <p className="text-gray-700 mt-6">
                                                Weitere Informationen zur Verwendung dieser Cookies und der
                                                Verarbeitung personenbezogener Daten findest du in unserer{" "}
                                                <StyledLink
                                                    to={"/datenschutz"}
                                                    className="text-blue-600 underline">Datenschutzerklärung
                                                </StyledLink>.
                                            </p>

                                            <h2 className="text-2xl mt-6">Präferenzen</h2>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Audiowiedergabe durch Spotify
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir binden Spotify ein, um dir hochwertige Audioinhalte direkt
                                                        auf
                                                        unserer Website zur Verfügung zu stellen.
                                                        Durch den Spotify-Player kannst du Podcast-Episoden und andere
                                                        Audioinhalte direkt anhören, ohne die Website verlassen zu
                                                        müssen.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Bei der Einbettung eines Spotify-Players über einen iframe
                                                        können
                                                        Daten wie deine IP-Adresse, gerätebezogene Informationen und
                                                        Interaktionen mit dem Player an Spotify übermittelt werden.
                                                        Diese
                                                        Daten werden von Spotify genutzt, um die Wiedergabe der
                                                        Audioinhalte
                                                        zu ermöglichen und zu optimieren.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                    des Cookies
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_fbp</td>
                                                                <td className="border px-4 py-2">Wird von Facebook
                                                                    genutzt,
                                                                    um Nutzer über Websites hinweg zu identifizieren und
                                                                    zu
                                                                    tracken.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    zur
                                                                    Erhebung von Website-Nutzungsdaten (z. B.
                                                                    Interaktionen).
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga_BMC5VGR8YS</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    für
                                                                    spezifische Datenanalysen und Tracking.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga_S0T2DJJFZM</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    zur
                                                                    Differenzierung von Sitzungen und Nutzern.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_t</td>
                                                                <td className="border px-4 py-2">Authentifizierungs-Token
                                                                    für Spotify-Interaktionen.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sss</td>
                                                                <td className="border px-4 py-2">Technisches Cookie zur
                                                                    Steuerung von Spotify-Player-Funktionen.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga_S35RN5WNT2</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    für
                                                                    Sitzungs- und Nutzerdatenanalyse.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga_ZWG1NSHWD8</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    zur
                                                                    Optimierung von Nutzerdaten.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ga_ZWRF3NLZJZ</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    zur
                                                                    langfristigen Nutzerverfolgung.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Jahre</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_gcl_au</td>
                                                                <td className="border px-4 py-2">Google AdSense-Cookie
                                                                    zur
                                                                    Analyse der Werbeleistung.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_gid</td>
                                                                <td className="border px-4 py-2">Google Analytics-Cookie
                                                                    zur
                                                                    Unterscheidung von Nutzern.
                                                                </td>
                                                                <td className="border px-4 py-2">24 Stunden</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_tt_enable_cookie</td>
                                                                <td className="border px-4 py-2">TikTok-Cookie zur
                                                                    Aktivierung von Werbe- und Tracking-Funktionen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ttp</td>
                                                                <td className="border px-4 py-2">TikTok-Cookie zur
                                                                    Messung
                                                                    der Werbewirksamkeit und -performance.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_adid</td>
                                                                <td className="border px-4 py-2">Spotify-Cookie zur
                                                                    Verfolgung von Werbeinteraktionen.
                                                                </td>
                                                                <td className="border px-4 py-2">1 Jahr</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_dc</td>
                                                                <td className="border px-4 py-2">Cookie zur Speicherung
                                                                    von
                                                                    Geräteinformationen für die Audiowiedergabe.
                                                                </td>
                                                                <td className="border px-4 py-2">1 Jahr</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_gaid</td>
                                                                <td className="border px-4 py-2">Cookie für
                                                                    gerätespezifische Werbung und Identifikation.
                                                                </td>
                                                                <td className="border px-4 py-2">1 Jahr</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_key</td>
                                                                <td className="border px-4 py-2">Authentifizierungs-Cookie
                                                                    für Spotify.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_landing</td>
                                                                <td className="border px-4 py-2">Verfolgt die
                                                                    Landing-Page-Informationen eines Nutzers.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">sp_last_utm</td>
                                                                <td className="border px-4 py-2">Speichert die letzte
                                                                    UTM-Quelle für Analysezwecke.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefSpotify}
                                                                    onToggle={(state) => {
                                                                        handleSpotifyConsent(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Spotify (Audio-Wiedergabe) aktivieren</label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink to="https://www.spotify.com/legal/privacy-policy/"
                                                                    className="text-blue-600 underline">Datenschutzerklärung
                                                            von
                                                            Spotify</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Videowiedergabe durch Vimeo
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir binden Vimeo ein, um dir hochwertige Videoinhalte direkt auf
                                                        unserer Website bereitzustellen. Mit dem Vimeo-Player kannst du
                                                        Videos ansehen, ohne unsere Website verlassen zu müssen.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Beim Abspielen von Videos über den Vimeo-Player können Cookies
                                                        gesetzt werden, um die Videowiedergabe zu ermöglichen, die
                                                        Performance zu verbessern und Nutzungsdaten für Analysezwecke zu
                                                        erfassen. Vimeo verarbeitet diese Daten gemäß seinen
                                                        Datenschutzrichtlinien.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">vuid</td>
                                                                <td className="border px-4 py-2">
                                                                    Speichert die eindeutige ID des Nutzers zur Analyse
                                                                    von Videonutzungsdaten.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">player</td>
                                                                <td className="border px-4 py-2">
                                                                    Speichert Einstellungen für den Vimeo-Player (z. B.
                                                                    Lautstärke, Qualität).
                                                                </td>
                                                                <td className="border px-4 py-2">1 Jahr</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_cfuvid</td>
                                                                <td className="border px-4 py-2">
                                                                    Cloudflare-Cookie zur Verbesserung der Sicherheit
                                                                    und Performance der Website.
                                                                </td>
                                                                <td className="border px-4 py-2">Sitzung</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">__cf_bm</td>
                                                                <td className="border px-4 py-2">
                                                                    Cloudflare-Cookie zur Identifizierung und
                                                                    Verhinderung von Bots.
                                                                </td>
                                                                <td className="border px-4 py-2">30 Minuten</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefVimeo}
                                                                    onToggle={(state) => {
                                                                        handleVimeoConsent(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Vimeo aktivieren
                                                        </label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink
                                                            to="https://vimeo.com/privacy"
                                                            className="text-blue-600 underline">Datenschutzerklärung
                                                            von Vimeo</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <h2 className="text-2xl mt-6">Marketing</h2>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Analyse der Werbeleistung durch Google AdSense
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir verwenden Google AdSense, um dir relevante Werbeanzeigen auf
                                                        unserer Website anzuzeigen. Dabei werden Anzeigen basierend auf
                                                        deinen Interessen und deinem Verhalten geschaltet, um dir eine
                                                        personalisierte Nutzererfahrung zu bieten.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Google AdSense nutzt Cookies, um Informationen über deine
                                                        Besuche auf unserer Website und anderen Websites zu sammeln.
                                                        Diese Daten werden verwendet, um relevante Anzeigen zu
                                                        platzieren und die Effektivität von Werbekampagnen zu messen.
                                                        Alle Daten werden gemäß den Datenschutzrichtlinien von Google
                                                        verarbeitet.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_gcl_au</td>
                                                                <td className="border px-4 py-2">
                                                                    Google AdSense-Cookie zur Analyse der Werbeleistung.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">IDE</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie von DoubleClick zur Optimierung und Messung
                                                                    von Werbeanzeigen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">ANID</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Anzeige von relevanter Werbung.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">DSID</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Identifikation von eingeloggten
                                                                    Nutzer:innen für personalisierte Werbung.
                                                                </td>
                                                                <td className="border px-4 py-2">2 Wochen</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">FLC</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Häufigkeitsbegrenzung von Anzeigen.
                                                                </td>
                                                                <td className="border px-4 py-2">10 Sekunden</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">AID</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Verknüpfung von Nutzeraktivitäten auf
                                                                    verschiedenen Geräten.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">NID</td>
                                                                <td className="border px-4 py-2">
                                                                    Google-Cookie zur Speicherung von Nutzerpräferenzen.
                                                                </td>
                                                                <td className="border px-4 py-2">6 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">__gads</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Schaltung und Messung von Anzeigen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">__gpi</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Analyse der Anzeigeninteraktion.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleAdSenseConsent(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Google AdSense aktivieren
                                                        </label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink
                                                            to="http://www.google.com/intl/de/policies/privacy"
                                                            className="text-blue-600 underline">Datenschutzerklärung
                                                            von Google</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Messung von Erfolgen durch Google Ads Conversion
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir nutzen Google Ads Conversion, um den Erfolg unserer
                                                        Werbekampagnen zu messen und zu verstehen, wie Nutzer:innen mit
                                                        unseren Anzeigen und unserer Website interagieren. Dies hilft
                                                        uns, unsere Werbemaßnahmen zu optimieren und relevantere
                                                        Angebote bereitzustellen.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Google Ads Conversion setzt Cookies ein, um Conversions wie
                                                        Käufe, Registrierungen oder andere Aktionen nachzuverfolgen, die
                                                        über eine Anzeige ausgelöst wurden. Diese Daten werden
                                                        anonymisiert gesammelt und ermöglichen es uns, die Effektivität
                                                        unserer Anzeigen zu bewerten, ohne persönliche Informationen der
                                                        Nutzer:innen preiszugeben.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_gcl_au</td>
                                                                <td className="border px-4 py-2">
                                                                    Google AdSense-Cookie zur Analyse der Werbeleistung.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">IDE</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie von DoubleClick zur Optimierung und Messung
                                                                    von Werbeanzeigen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">1P_JAR</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Analyse von Website- und
                                                                    Werbenutzungsdaten.
                                                                </td>
                                                                <td className="border px-4 py-2">1 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_gcl_aw</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Verknüpfung von Klickdaten mit
                                                                    Conversion-Daten.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">NID</td>
                                                                <td className="border px-4 py-2">
                                                                    oogle-Cookie zur Speicherung von Nutzerpräferenzen.
                                                                </td>
                                                                <td className="border px-4 py-2">6 Monate</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleGoogleAdsConversion(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Google Ads Conversion aktivieren
                                                        </label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink
                                                            to="http://www.google.com/intl/de/policies/privacy"
                                                            className="text-blue-600 underline">Datenschutzerklärung
                                                            von Google</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Personalisierte Werbung durch Google Ads Remarketing
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir nutzen Google Ads Remarketing, um dir personalisierte
                                                        Werbung basierend auf deinen Interessen und deinem Verhalten auf
                                                        unserer Website zu präsentieren. Dies ermöglicht es uns,
                                                        relevante Anzeigen zu schalten, die zu deinen Interessen passen,
                                                        auch nachdem du unsere Website verlassen hast.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Google Ads Remarketing verwendet Cookies, um deine Interaktionen
                                                        auf unserer Website zu verfolgen und diese Informationen mit
                                                        deinem Google-Konto oder deinen gerätebezogenen Daten zu
                                                        verknüpfen. So können dir relevante Anzeigen auf anderen
                                                        Websites im Google-Werbenetzwerk angezeigt werden. Die erhobenen
                                                        Daten bleiben anonym und werden nicht dazu verwendet, dich
                                                        persönlich zu identifizieren.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_gcl_au</td>
                                                                <td className="border px-4 py-2">
                                                                    Google AdSense-Cookie zur Analyse der Werbeleistung.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">IDE</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie von DoubleClick zur Optimierung und Messung
                                                                    von Werbeanzeigen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">1P_JAR</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Analyse von Website- und
                                                                    Werbenutzungsdaten.
                                                                </td>
                                                                <td className="border px-4 py-2">1 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">ANID</td>
                                                                <td className="border px-4 py-2">
                                                                    Cookie zur Anzeige von relevanter Werbung.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">NID</td>
                                                                <td className="border px-4 py-2">
                                                                    oogle-Cookie zur Speicherung von Nutzerpräferenzen.
                                                                </td>
                                                                <td className="border px-4 py-2">6 Monate</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleGoogleAdsRemarketing(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Google Ads Remarketing aktivieren
                                                        </label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink
                                                            to="http://www.google.com/intl/de/policies/privacy"
                                                            className="text-blue-600 underline">Datenschutzerklärung
                                                            von Google</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900 underline">
                                                        Relevante Werbung durch TikTok Ads
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir verwenden TikTok Ads, um dir automatisch relevante Werbung
                                                        und Angebote zu präsentieren, beispielsweise in der TikTok-App.
                                                        Dafür setzt TikTok unter anderem einen eigenen Pixel ein, der
                                                        dein Nutzungsverhalten von der Werbeanzeige bis auf unsere
                                                        Website verfolgt und dabei Aktionen wie Käufe oder Klicks
                                                        erfasst. So können wir Conversions im TikTok Ads Manager
                                                        auswerten und unsere Angebote kontinuierlich verbessern. Alle
                                                        erhobenen Informationen bleiben vertraulich.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Der TikTok Pixel erfasst Nutzungsdaten wie Seitenaufrufe, Klicks
                                                        und Käufe, um die Performance von Werbeanzeigen zu messen. Diese
                                                        Daten ermöglichen es uns, Werbekampagnen gezielt zu optimieren
                                                        und dir Inhalte anzuzeigen, die für dich relevant sind. TikTok
                                                        verarbeitet die gesammelten Daten gemäß den
                                                        Datenschutzbestimmungen.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_tt_enable_cookie</td>
                                                                <td className="border px-4 py-2">
                                                                    TikTok-Cookie zur Aktivierung von Werbe- und
                                                                    Tracking-Funktionen.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">_ttp</td>
                                                                <td className="border px-4 py-2">
                                                                    TikTok-Cookie zur Messung der Werbewirksamkeit und
                                                                    -performance.
                                                                </td>
                                                                <td className="border px-4 py-2">13 Monate</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleTikTokPixel(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            TikTok Ads aktivieren</label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink
                                                            to="https://www.tiktok.com/legal/page/eea/privacy-policy/de"
                                                            className="text-blue-600 underline">Datenschutzerklärung
                                                            von
                                                            TikTok</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900  underline">
                                                        Optimierte Nutzererfahrung durch Analyse mit Meta Pixel
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Wir verwenden das Meta Pixel, um dein Nutzerverhalten besser zu
                                                        verstehen und unsere Inhalte sowie Angebote optimal auf deine
                                                        Bedürfnisse anzupassen. Dabei bleiben deine Daten geschützt und
                                                        werden nur für Analysezwecke verwendet.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        Das Meta Pixel erfasst Daten wie Seitenaufrufe, Interaktionen
                                                        und Conversions, um eine bessere Analyse des Nutzerverhaltens zu
                                                        ermöglichen. Diese Daten helfen uns, unsere Inhalte und
                                                        Marketingmaßnahmen gezielt zu verbessern und für dich relevanter
                                                        zu gestalten. Es erfolgt keine Weitergabe deiner
                                                        personenbezogenen Daten an unbefugte Dritte.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">_fbp</td>
                                                                <td className="border px-4 py-2">Wird von Facebook
                                                                    genutzt,
                                                                    um Nutzer über Websites hinweg zu identifizieren und
                                                                    zu
                                                                    tracken.
                                                                </td>
                                                                <td className="border px-4 py-2">3 Monate</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                    <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleMetaPixelConsent(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            Meta Pixel aktivieren</label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink to="https://www.facebook.com/privacy/policy/"
                                                                    className="text-blue-600 underline">Datenschutzerklärung
                                                            von
                                                            Meta</StyledLink> (früher Facebook).
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="relative mt-6 flex-1">
                                                <div className="mt-6">
                                                    <h3 className="text-xl font-semibold text-gray-900  underline">
                                                        Personalisierte Chat-Interaktionen und Kampagnen-Analyse
                                                    </h3>
                                                    <p className="text-gray-700 my-4">
                                                        Unsere Website nutzt ManyChat, ein Tool zur Automatisierung von
                                                        Chat-Interaktionen. ManyChat hilft uns, dir eine personalisierte
                                                        und effektive Kommunikation zu bieten. Dabei setzt ManyChat
                                                        Cookies ein, um bestimmte Funktionen wie Events zu ermöglichen
                                                        und deine Benutzererfahrung zu
                                                        optimieren. Diese Cookies speichern Informationen, die uns
                                                        helfen, den Erfolg unserer Kampagnen zu messen und dir gezielte
                                                        Inhalte bereitzustellen.
                                                    </p>

                                                    <h4 className="text-lg font-semibold text-gray-900">Funktionsweise</h4>
                                                    <p className="text-gray-700 mb-6">
                                                        ManyChat verwendet Cookies, um deine Aktivitäten auf unserer
                                                        Website zu verfolgen und zu analysieren. Diese Informationen
                                                        unterstützen uns bei der Erfassung von Conversion Events (z. B.
                                                        wenn du auf einen Link klickst oder dich für unseren Newsletter
                                                        anmeldest) und Money Events (z. B. wenn du einen Kauf tätigst).
                                                        Durch diese Datenerhebung können wir die Performance unserer
                                                        Angebote verbessern und dir ein besseres Nutzererlebnis bieten.
                                                    </p>

                                                    <h2 className="text-xl font-semibold mb-4">Detaillierte
                                                        Informationen zu
                                                        Cookies</h2>
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full bg-white border border-gray-300">
                                                            <thead className="bg-gray-100">
                                                            <tr>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name
                                                                </th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Zweck</th>
                                                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Speicherdauer</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-sm text-gray-700">
                                                            <tr>
                                                                <td className="border px-4 py-2">manychat_user_id</td>
                                                                <td className="border px-4 py-2">
                                                                    Identifiziert dich, um personalisierte
                                                                    Chat-Nachrichten und Interaktionen zu ermöglichen.
                                                                </td>
                                                                <td className="border px-4 py-2">30 Tage</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">manychat_conversion</td>
                                                                <td className="border px-4 py-2">
                                                                    Speichert Informationen über abgeschlossene
                                                                    Conversion Events, z. B. Klicks oder
                                                                    Formular-Abschlüsse.
                                                                </td>
                                                                <td className="border px-4 py-2">7 Tage</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">manychat_money_event</td>
                                                                <td className="border px-4 py-2">
                                                                    Erfasst Daten zu Money Events, z. B. Kaufabschlüsse,
                                                                    zur Analyse von Umsätzen.
                                                                </td>
                                                                <td className="border px-4 py-2">7 Tage</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">manychat_session</td>
                                                                <td className="border px-4 py-2">
                                                                    Verfolgt deine aktuelle Sitzung, um reibungslose
                                                                    Interaktionen innerhalb eines Flows sicherzustellen.
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    Session (bis Sitzungsende)
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border px-4 py-2">manychat_tracking_id</td>
                                                                <td className="border px-4 py-2">
                                                                    Dient der Analyse und Verbesserung von
                                                                    Kampagnen-Performance durch anonymisierte
                                                                    Tracking-Daten.
                                                                </td>
                                                                <td className="border px-4 py-2">
                                                                    30 Tage
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
                                                        Individuelle Einwilligung
                                                    </h4>
                                                    <div className="flex items-center space-x-2">
                                                        Du kannst der Nutzung dieser Cookies über unseren
                                                        Cookie-Consent-Manager zustimmen oder sie ablehnen. Beachte
                                                        jedoch, dass einige Funktionen, wie personalisierte
                                                        Chat-Nachrichten und Kaufanalysen, ohne diese Cookies
                                                        möglicherweise eingeschränkt sind.
                                                        <span className="ml-4 shrink-0 w-24">
                                                                <ToggleButton
                                                                    enabled={userPrefMetaPixel}
                                                                    onToggle={(state) => {
                                                                        handleManyChatPixel(state);
                                                                    }}
                                                                />
                                                            </span>
                                                        <label htmlFor="spotify" className="text-gray-700">
                                                            ManyChat Pixel aktivieren</label>
                                                    </div>

                                                    <p className="text-gray-700 mt-6">
                                                        Weitere Informationen zur Verwendung dieser Cookies und der
                                                        Verarbeitung personenbezogener Daten findest du in der{" "}
                                                        <StyledLink to="https://manychat.com/legal/privacy"
                                                                    className="text-blue-600 underline">Datenschutzerklärung
                                                            von
                                                            ManChat</StyledLink>.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}

            <Outlet/>
            <script
                dangerouslySetInnerHTML={{
                    __html: `window.ENV = ${JSON.stringify(data.ENV)};`,
                }}
            />
            <Footer showOnly={[IMPRINT, PRIVACY]} showIcons={false}/>
        </>
    );
}
