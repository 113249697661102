import {Switch} from "@headlessui/react";

interface ToggleButtonProps {
    enabled: boolean; // Aktueller Zustand
    onToggle: (enabled: boolean, currentState: boolean) => void; // Callback
    disabled?: boolean; // Deaktivierungszustand
    ariaLabel?: string;
    className?: string;
}

export default function ToggleButton({
                                         enabled,
                                         onToggle,
                                         disabled = false, // Standardmäßig nicht deaktiviert
                                         ariaLabel = "Toggle setting",
                                         className = "",
                                     }: ToggleButtonProps) {
    return (
        <Switch
            checked={enabled}
            onChange={(newState) => !disabled && onToggle(newState, enabled)} // Nur, wenn nicht deaktiviert
            className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600 ${
                disabled
                    ? "cursor-not-allowed bg-gray-400 opacity-50" // Styling für deaktiviert
                    : "bg-gray-200"
            } ${className}`}
            disabled={disabled} // Übergabe des disabled-Props
        >
            <span className="sr-only">{ariaLabel}</span>
            <span
                className="pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
        <span
            aria-hidden="true"
            className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
        >
          {/* Optional: Icons für unchecked state */}
        </span>
        <span
            aria-hidden="true"
            className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
        >
          {/* Optional: Icons für checked state */}
        </span>
      </span>
        </Switch>
    );
}
