import { Link } from "@remix-run/react";

type StyledLinkProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
  type?: "primary" | "secondary";
  reloadDocument?: boolean;
  openInNewTab?: boolean; // Neues Prop für das Öffnen in neuem Tab
};

const StyledLink: React.FC<StyledLinkProps> = ({
  to,
  children,
  className = "",
  type = "primary",
  reloadDocument,
  openInNewTab = false, // Standardmäßig auf false setzen
}: StyledLinkProps) => {
  const colorClass = (() => {
    switch (type) {
      case "primary":
        return "text-blue-500";
      case "secondary":
        return "text-[#8bd8bd]";
      default:
        return "text-black";
    }
  })();

  const isExternal = to.startsWith("http");

  if (isExternal || openInNewTab) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={`font-semibold underline ${colorClass} ${className}`}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      {...(reloadDocument ? { reloadDocument: true } : {})}
      className={`font-semibold underline ${colorClass} ${className}`}
    >
      {children}
    </Link>
  );
};

export default StyledLink;
